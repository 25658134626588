/*
 * Application
 */

var settings = {
  wizard: {
    options: {
      interval: false
    },
    selection: [
      'room-OPEN', 'room-CLOSED',
      'shape-island', 'shape-L_SHAPE', 'shape-U_SHAPE',
      'color-light', 'color-dark', 'color-colorful',
      'style-modern', 'style-country', 'style-classic'
    ],
    path: {}
  },
  carousel: {
    options: {
      interval: 6000
    }
  }
};

// Wizard options

function searchFormRender() {
  // hide delete filter
  $('#searchForm #deleteFilter').hide();
  $('#searchForm .searchHint').show();
  // show options
  $.each(settings.wizard.selection, function(k, o) {
    $('#option-'+o).toggle($('#'+o).is(':checked'));
    if ($('#'+o).is(':checked')) {
      $('#searchForm #deleteFilter').show();
      $('#searchForm .searchHint').hide();
    }
  });
}

// jQuery
$(document).ready(function() {
  'use strict';

  /************
    ALL
   ************/

  // Scroll up
  $(window).scroll(function() {
    if ($(this).height()+$(this).scrollTop() >= $(document).height()-100) {
      $('#return-to-top').css('margin-bottom', -1*($(document).height()-$(this).height()-$(this).scrollTop()));
    } else {
      $('#return-to-top').css('margin-bottom', '-100px');
    }
    if ($(document).width() >= 767) {
      if ($(this).scrollTop() >= 450) {
        $('#return-to-top').fadeIn(400);
      } else {
        $('#return-to-top').fadeOut(400);
      }
    }
  });
  $('#return-to-top').click(function() {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
  });

  /************
    Home
   ************/
  
  // Home: Bootstrap Carousel Wizard
  if (typeof settings.wizard.options !== 'undefined') {
    // Init Carousel
    $('#searchWizard').carousel(settings.wizard.options);
    // On slide change
    $('#searchWizard').on('slid.bs.carousel', function(evt) {
      if ($(evt.relatedTarget).index() == 0) {
        $(this).find('.carousel-indicators').hide();
      } else {
        $('#searchWizard .carousel-indicators').fadeIn();    
      }
    });
    $('#searchWizard').on('slide.bs.carousel', function(evt) {
      if ($(evt.relatedTarget).index() == 0) {
        $(this).find('.carousel-indicators').hide();
      }
    });
    $('#searchWizard').on('click', '.btn-wizard', function(evt) {
      evt.preventDefault();
    });
    // buttons: toggle active state
    $('#searchWizard').on('mousedown', '.btn-wizard', function(evt) {
      evt.preventDefault();
      var name = $(this).attr('data-name');
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        settings.wizard.path[name] = 'on';
      } else {
        delete settings.wizard.path[name];
      }
    });
    $('#searchWizard').on('click', '.submitWizard', function(evt) {
      window.location = 'kuechen?' + $.param(settings.wizard.path);
      // example: http://gedk-kompass/kuechen?room-OPEN=on&shape-U_SHAPE=on&color-dark=on&style-classic=on&style-country=on#close
      evt.preventDefault();
    });
  }

  /************
    Modal
   ************/

  // Remote Modal Fix
  var modal = $('#theme-modal').html();
  $('body').on('hidden.bs.modal', '.modal', function () {
    $(this).removeData('bs.modal');
    $('#theme-modal').html(modal);
  });

  /************
    Search Result
   ************/

  // Search Result: Bootstrap Carousel Wizard
  if (typeof settings.wizard.options !== 'undefined') {
    // Init Carousel
    $('#resultDetailsCarousel').carousel(settings.carousel.options);
  }
  // Search Result: Tabs
  $('.tabContent').hide();
  $('#tabContent0').show();
  $('.nav-tabs a').click(function(evt) {
    evt.preventDefault();
    // $(this).tab('show');    
    $('.tabContent').hide();
    $('#tabContent' + this.id).show();
    $('.nav-tabs li').removeClass('active');
    $(this).closest('li').addClass('active');
  })

  /************
    Search
   ************/

  // Search Navbar 
  $('#searchNavi #searchNaviOptions .dropdown-menu li').on('click', 'a', function(evt) {
    // evt.preventDefault();
    $(this).closest('.btn-group').addClass('open');
  });
  // Search Navbar Options X
  $('#searchNavi #searchNaviOptions .searchOption li div').on('click', 'a', function(evt) {
    evt.preventDefault();
    $(this).closest('div').toggle();
  });
  // Search Form
  $('#searchForm').on('change', function() {
    // Request from api
    $('#searchForm').submit();
    searchFormRender();
  });
  // Search Form: Option Blocks Delete this filter
  $('#searchForm .option-block').on('click', 'a', function() {
    var id = $(this).closest('div.option-block').attr('id').replace('option-', '');
    $('#'+id).prop("checked", false);
    $('#searchForm').trigger('change');
  });
  // Search Form: Delete all filter
  $('#searchForm').on('click', '#deleteFilter', function() {
    $.each(settings.wizard.selection, function(k, o) {
      $('#'+o).prop("checked", false);
      $('#option-'+o).hide();
    });
    $('#searchForm #zip').val('');
    $('#option-zip').hide();
    $('#searchForm').trigger('change');
  });

  // Init Form
  searchFormRender();

  $('#searchForm #option-zip').on('keydown', '#zip', function(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
       // Allow: Ctrl+A, Command+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) || 
       // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }
  });
  // Search Form: Submit
  $('#searchForm #option-zip').on('keyup', '#zip', function() {
    if ($(this).val().length < 5) {
      $('#searchForm #option-zip .btn-primary').addClass('disabled');
    } else {
      $('#searchForm #option-zip .btn-primary').removeClass('disabled');
    }
  });
  // Search Form: Delete
  $('#searchForm #option-zip').on('click', '#distanceClear', function(evt) {
    evt.preventDefault();
    $('#searchForm #zip').val('');
    $('#searchForm #zip').prev('label').removeClass('active');
    $('#searchForm #distance').val('');    
    $('#searchForm #distance').prev('label').removeClass('active');
    $('#searchForm').trigger('change');
    $('#option-zip .error-message').slideUp();
  });
  // Search Form: Distance Dropdown
  $('#searchForm #option-zip .dropdown-menu').on('click', 'a', function(evt) {
    evt.preventDefault();
    $('#searchForm #distance').val($(this).text());
    $('#searchForm #distance').prev('label').addClass('active');
    if ($('#searchForm #zip').val() != '') {
      $('#searchForm').trigger('change');
    }
  });

  // Materialize Parallax
  $('.parallax').parallax();
  // Responsive Grid
  $('#apiResults .grid').initResponsiveGrid();
});

/************
  Isotop Grid
 ************/

// Bootstrap Toolkit Responsive Behaviour for Isotope Grid
(function($, viewport){

  var _this;
  var grid;
  var columnWidth = 0;
  var viewportSize = '';

  function setGrid(force) {
    var columnWidthNew = 0;
    var isGrid = _this.hasClass('view-grid');
    if (_this.hasClass('view-grid')) {
      if( viewport.is('xs') ) { viewportSize = 'xs'; columnWidthNew = null; }
      if( viewport.is('sm') ) { viewportSize = 'sm'; columnWidthNew = 374; }
      if( viewport.is('md') ) { viewportSize = 'md'; columnWidthNew = 484; }
      if( viewport.is('lg') ) { viewportSize = 'lg'; columnWidthNew = 390; }
    } else {
      columnWidthNew = null;
    }
    if (columnWidth != columnWidthNew || force) {
      columnWidth = columnWidthNew;
      // change item class
      _this.find('.grid-item').removeClass('grid-item-xs grid-item-sm grid-item-md grid-item-lg');
      if (isGrid) {
        _this.find('.grid-item').addClass('grid-item-'+viewportSize);
      }
      // Create new isotope
      grid = _this.isotope({
        itemSelector: '.grid-item',
        masonry: {
          columnWidth: columnWidth
        }
      });
    }
  }

  $.fn.initResponsiveGrid = function() {
    _this = this;
    // Set Isotope Grid
    setGrid(true);
    // Add Listener to every item
    grid.on('click', '.grid-item .card', function(evt) {
      var isGrid = $('#apiResults .grid').hasClass('view-grid');
      if (isGrid) {
        evt.preventDefault();
        // change size of item by toggling gigante class
        $(this).closest('.grid-item').toggleClass('gigante');
        grid.isotope('layout');
      }
    });
    // Search Settings Navi
    $('#settingsNavi .settingsNaviSort .dropdown-menu').on('click', 'a', function(evt) {
      evt.preventDefault();
      $('#settingsNavi .settingsNaviSort .selection').html($(this).html());
      // set form value
      $('#searchForm #sortBy').val($(this).attr('value'));
      $('#searchForm').trigger('change');
    });
    // Search Settings View: Grid
    $('#settingsNavi').on('click', 'a#settingsNaviViewGrid', function(evt) {
      evt.preventDefault();
      // set classes
      $('#settingsNavi #settingsNaviViewList').removeClass('active');
      $(this).addClass('active');
      _this.removeClass('view-list');
      _this.addClass('view-grid');
      // Set search form value
      $('form#searchMetaData #view').val('grid');
      $('form#searchMetaData').submit();
      setGrid(true);
    });
    // Search Settings View: List
    $('#settingsNavi').on('click', 'a#settingsNaviViewList', function(evt) {
      evt.preventDefault();
      // set classes
      $('#settingsNavi #settingsNaviViewGrid').removeClass('active');
      $(this).addClass('active');
      _this.removeClass('view-grid');
      _this.addClass('view-list');
      // Set search form value
      $('form#searchMetaData #view').val('list');
      $('form#searchMetaData').submit();
      setGrid(true);
    });
    // Page/Pagination Buttons
    $('.pagination').on('click', 'a.pageButton', function(evt) {
      evt.preventDefault();
      $('form#searchForm').attr('action', $(this).text());
      $('form#searchForm')[0].submit();
    });
  };

  // Execute code each time window size changes
  $(window).resize(
    viewport.changed(function() {
      setGrid(false);
    })
  ); 
})(jQuery, ResponsiveBootstrapToolkit);